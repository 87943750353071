vue
<template>
  <div>
    <div class="p-grid">
      <div class="p-col-12 p-d-flex p-jc-center">
        <p class="title-bold">Láser de diodo</p>
      </div>
    </div>

    <div class="p-grid">
      <div class="p-col-12 p-d-flex p-jc-center">
        <img class="img-fluid" alt="fiber pro 2.0" src="../../assets/laser.jpg" />
      </div>

      <div class="p-col-10 p-offset-1 p-d-flex p-jc-center">
        <Card styleClass="p-card-shadow">
          <template #content>

            <p>La depilación láser con la máquina Fiber Pro 2.0 es un procedimiento <span
                class="p-text-bold">eficaz</span> y seguro para eliminar el vello no deseado. Aunque es normal sentir
              cierta sensación de calor durante el tratamiento, la mayoría de las personas lo toleran bien y no
              experimentan <span class="p-text-bold">dolor</span> significativo.</p>

            <p>Es importante destacar que la depilación láser se puede realizar en diferentes tipos de piel, incluyendo
              piel <span class="p-text-bold">clara</span>, <span class="p-text-bold">oscura</span> y <span
                class="p-text-bold">bronceada</span>. Sin embargo, es recomendable que consultes con un profesional para
              determinar si eres un candidato adecuado y recibir una evaluación personalizada.</p>

            <p>La cantidad de sesiones necesarias puede variar según varios factores, como el área a tratar, el tipo de
              vello y las características individuales de cada persona. Por lo general, se recomiendan entre <span
                class="p-text-bold">6 y 8 sesiones</span> para obtener resultados óptimos. Es importante seguir el plan de
              tratamiento recomendado por el especialista para lograr los mejores resultados.</p>
          </template>

          <template #footer>
            <span>Para más información no dude en ponerse en contacto con nuestro
              Centro médico al 637 857 384.</span>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from 'primevue/card';
export default {
  name: 'PsicologiaVue',
  data() {
    return {
      // Propiedades de datos del componente
    };
  },
  components: {
    Card
  },
  methods: {
    // Métodos del componente
  },
  computed: {
    // Propiedades computadas del componente
  },
  created() {
    // Lógica que se ejecuta cuando el componente se crea
  },
  mounted() {
    // Lógica que se ejecuta cuando el componente se monta en el DOM
  },
  destroyed() {
    // Lógica que se ejecuta cuando el componente se destruye
  },
};
</script>

<style scoped>
.title-bold {
  font-size: 30px;
  font-weight: bold
}

.img-fluid {
  width: 100%;
  height: auto;
}

@media (min-width: 576px) {
  .img-fluid {
    max-width: 50%;
    height: auto;
  }
}</style>